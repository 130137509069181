import React from "react";
import theme from "theme";
import { Theme, Box, Link, Section, Image, Text, Strong, Input, Button, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoMdMenu, IoIosMenu } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				 We create coolest digital products
			</title>
			<meta name={"description"} content={"We turn your ideas into working solutions"} />
			<meta property={"og:title"} content={"Code Word |  coolest digital products"} />
			<meta property={"og:description"} content={"We turn your ideas into working solutions"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<Box
			empty-border-width="1px"
			empty-min-width="0px"
			empty-min-height="0px"
			position="absolute"
			empty-border-color="LightGray"
			height="0px"
			width="0px"
			min-width="0px"
			quarkly-title="agency"
			top="0px"
			id="agency"
			empty-border-style="solid"
			min-height="0px"
			z-index="0"
		/>
		<Box
			empty-min-height="0px"
			empty-border-width="1px"
			empty-border-color="LightGray"
			width="0px"
			min-width="0px"
			id="works"
			height="0px"
			quarkly-title="works"
			z-index="0"
			top="calc(200vh - 50px)"
			min-height="0px"
			position="absolute"
			empty-min-width="0px"
			empty-border-style="solid"
			sm-min-width="0px"
		/>
		<Section
			top="0px"
			background="--color-light"
			sm-padding="0px 8px 0px 8px"
			margin="0 0 px 0"
			height="40px"
			padding="0px 32px 0px 32px"
			position="sticky"
			quarkly-title="menu1"
			min-width="100wv"
			width="100wv"
			z-index="12"
		>
			<Override
				slot="SectionContent"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				align-items="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
			/>
			<Link
				font="--menu"
				text-decoration-line="initial"
				text-transform="uppercase"
				href="#agency"
				color="--dark"
			>
				Code Word
			</Link>
		</Section>
		<Components.QuarklycommunityKitMobileSidePanel
			breakpoint="all"
			z-index="90"
			menuPosition="full"
			margin="-38px 0px 0px 0px"
			position="sticky"
			sm-margin="-34px 0px 0px 0px"
			sm-position="sticky"
			sm-top="7px"
			sm-z-index="120"
			animDuration="0.5s"
			top="11px"
		>
			<Override slot="Button Icon :closed" icon={IoMdMenu} category="io" color="--dark" />
			<Override slot="Children" width="100%" />
			<Override slot="Overlay" position="sticky" />
			<Override slot="Cross" color="--orange" />
			<Override
				slot="Button Text"
				sm-width="16px"
				sm-height="16px"
				sm-min-width="16px"
				sm-min-height="16px"
			/>
			<Override slot="Button Text :closed">
				{" "}
			</Override>
			<Override slot="Button Icon :open" icon={IoIosMenu} category="io" />
			<Override
				slot="Button Icon"
				color="--orange"
				size="30px"
				sm-margin="0px 10px 0px 0px"
				category="io"
				icon={IoMdMenu}
				margin="-4px 16px 0px 0px"
			/>
			<Override
				slot="Content :open"
				bottom="0px"
				sm-left="6vw"
				sm-top="0px"
				height="100vh"
				sm-width="94vw"
				sm-height="100vh"
				hover-width="75vw"
				hover-height="100vh"
				sm-max-width="94vw"
				width="75vw"
				min-width="75vw"
				border-color="--color-dark"
				max-width="75vw"
				left="25vw"
				padding="20px 16px 20px 16px"
				sm-min-width="94vw"
			/>
			<Override
				slot="Content"
				width="10vw"
				height="100vh"
				background="--color-dark"
				left="100vw"
				sm-height="100vh"
				sm-width="10vw"
				border-color="--color-dark"
				max-height="100vh"
			/>
			<Box
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				height="100%"
				min-height="100%"
			>
				<Box
					margin="0px 0px 40px 0px"
					height="max-content"
					sm-margin="0px 0px 20px 0px"
					sm-width="100%"
					sm-min-width="100%"
				>
					<Box
						height="170px"
						sm-margin="0px 0px 20px 0px"
						quarkly-title="Logo"
						sm-width="100%"
						sm-min-width="100%"
						margin="0px 0px 60px 0px"
					>
						<Image
							object-position="0%"
							width="100%"
							height="170px"
							sm-width="100%"
							sm-display="inline-block"
							sm-height="124px"
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/00-logo.svg?v=2021-08-06T06:07:23.438Z"
						/>
					</Box>
					<Box height="max-content" display="flex" flex-direction="column" justify-content="flex-start">
						<Box
							min-width="100%"
							quarkly-title="agencyBox"
							margin="0px 0px 11px 0px"
							height="max-content"
							min-height="max-content"
							width="100%"
						>
							<Link
								sm-font="--text"
								href="#agency"
								color="--light"
								text-decoration-line="initial"
								font="--headline3"
								margin="0 0 0 0"
							>
								Agency
							</Link>
						</Box>
						<Box
							margin="0px 0px 11px 0px"
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="agencyBox"
						>
							<Link
								href="#works"
								color="--light"
								text-decoration-line="initial"
								font="--headline3"
								margin="0 0 0 0"
								sm-font="--text"
							>
								Works
							</Link>
						</Box>
						<Box
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="servicesBox"
							margin="0px 0px 11px 0px"
						>
							<Link
								color="--light"
								text-decoration-line="initial"
								font="--headline3"
								margin="0 0 0 0"
								sm-font="--text"
								href="#service"
							>
								Services
							</Link>
						</Box>
						<Box
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="projectsBox"
							display="none"
							margin="0px 0px 11px 0px"
						>
							<Text
								quarkly-title="projectsText"
								sm-color="--light"
								sm-font="--text"
								font="--headline3"
								color="--light"
								margin="0px 0px 0px 0px"
							>
								Проекты
							</Text>
						</Box>
						<Box
							height="max-content"
							min-height="max-content"
							width="100%"
							min-width="100%"
							quarkly-title="articlesBox"
							margin="0px 0px 11px 0px"
						>
							<Link
								sm-font="--text"
								href="#about"
								color="--light"
								text-decoration-line="initial"
								font="--headline3"
								margin="0 0 0 0"
							>
								Contacts
							</Link>
						</Box>
					</Box>
				</Box>
				<Box
					flex-direction="column"
					sm-width="max-content"
					sm-display="none"
					height="120px"
					display="none"
					align-items="flex-start"
					justify-content="flex-start"
					sm-height="100px"
				>
					<Text
						sm-min-width="max-content"
						sm-height="max-content"
						sm-min-height="max-content"
						sm-font="--text"
						font="--headline3"
						color="#ed5d40"
						sm-width="max-content"
					>
						Контакты
					</Text>
					<Box
						justify-content="flex-start"
						sm-height="100px"
						width="max-content"
						height="m"
						display="flex"
						align-items="center"
					>
						<Image width="34px" height="34px" src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z" margin="0px 12px 0px 0px" />
						<Image width="34px" height="34px" src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z" margin="0px 12px 0px 0px" />
					</Box>
				</Box>
			</Box>
		</Components.QuarklycommunityKitMobileSidePanel>
		<Section
			top="40px"
			sm-padding="8px 8px 8px 8px"
			quarkly-title="Section1"
			background="--color-light"
			padding="24px 32px 24px 32px"
			min-width="100wv"
			height="calc(100vh - 40px)"
			position="sticky"
			z-index="3"
			width="100wv"
		>
			<Override
				slot="SectionContent"
				color="--dark"
				justify-content="center"
				sm-justify-content="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
			/>
			<Text
				sm-font="--h1Mob"
				md-font="--h1Mob"
				quarkly-title="h1"
				color="--dark"
				sm-margin="20vh 0 0 0"
				font="--headline1"
			>
				We create
				<br />
				<Strong
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					sm-font="900 12vw/1.0 --fontFamily-googleMontserrat"
				>
					cool digital products
				</Strong>
			</Text>
		</Section>
		<Section
			top="40px"
			z-index="4"
			sm-padding="8px 8px 8px 8px"
			quarkly-title="Section2"
			border-radius="20px 20px 0px 0px"
			width="100wv"
			height="calc(100vh - 40px)"
			min-height="calc(100vh - 40px)"
			sm-border-radius="15px 15px 0px 0px"
			position="sticky"
			min-width="100wv"
			padding="24px 32px 24px 32px"
			background="--color-orange"
		>
			<Override
				slot="SectionContent"
				justify-content="center"
				sm-justify-content="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
			/>
			<Text
				sm-margin="20vh 0 0 0"
				font="--headline1"
				color="--light"
				md-font="--h2Mob"
				quarkly-title="description"
				sm-font="--h2Mob"
			>
				We turn your ideas
				<br />
				<Strong
					color="--dark"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					into working solutions
				</Strong>
			</Text>
		</Section>
		<Section
			position="sticky"
			background="--color-dark"
			quarkly-title="menu2"
			sm-border-radius="15px 15px 0px 0px"
			border-radius="20px 20px 0px 0px"
			width="100wv"
			min-width="100wv"
			height="40px"
			sm-padding="0px 8px 0px 8px"
			padding="1px 32px 1px 32px"
			z-index="12"
			top="40px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				align-items="flex-start"
			/>
			<Link href="#works" color="--light" font="--menu" text-decoration-line="initial">
				Projects
			</Link>
		</Section>
		<Section
			sm-padding="8px 8px 8px 8px"
			width="100wv"
			min-width="100wv"
			height="calc(100vh - 80px)"
			padding="24px 32px 24px 32px"
			z-index="4"
			top="80px"
			min-height="450px"
			position="sticky"
			quarkly-title="Section1"
			background="--color-dark"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="center"
				sm-justify-content="flex-start"
			/>
			<Text
				color="--light"
				sm-margin="20vh 0 0 0"
				font="--headline1"
				sm-font="--h1Mob"
				md-font="--h1Mob"
				quarkly-title="h1"
				sm-white-space="inherit"
			>
				Our latest{" "}
				<br />
				<Strong
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					projects
				</Strong>
			</Text>
		</Section>
		<Components.CaseProd
			sm-min-height="calc(100vh - 80px)"
			margin="0 0 0px 0"
			sm-margin="0px 0 0px 0"
			sm-height="calc(100vh - 80px)"
			sm-padding="0px 8px 8px 8px"
		>
			<Override slot="text3" sm-display="flex" sm-margin="12px 0 4px 0" color="inherit">
				Functional
			</Override>
			<Override slot="text5" sm-display="flex" sm-margin="12px 0 4px 0" color="inherit">
				Решение
			</Override>
			<Override slot="text6" sm-display="flex" color="inherit">
				Бэкенд на Directual,{" "}
				<br />
				Фронт на Directual и телеграмм
			</Override>
			<Override slot="text" sm-margin="12px 0px 12px 0px" color="inherit">
				Vitrina 22|52
			</Override>
			<Override slot="image" src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/Untitled-2.png?v=2022-02-16T14:33:45.763Z" object-fit="cover" sm-height="400px" />
			<Override slot="box2" sm-height="max-content" />
			<Override slot="box1" />
			<Override slot="text2" sm-border-color="--color-orange" color="inherit">
				The coolest and breakthrough line staff searching platform built on a telegram bot
			</Override>
			<Override
				slot="box"
				sm-margin="0 0px 0px 0px"
				border-radius="0px 20px 20px 0px"
				sm-border-radius="0px 0px 20px 20px"
				sm-height="calc(100% - 200px)"
				padding="20px 20px 20px 20px"
				background="#9A5DDB"
				color="--light"
				sm-padding="16px 8px 8px 8px"
			/>
			<Override slot="text1" sm-margin="0px 0 4px 0" color="inherit">
				About the project
			</Override>
			<Override slot="SectionContent" sm-height="100%" />
			<Override
				slot="sectionOverride"
				sm-justify-content="flex-start"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0 0 0"
				sm-flex-direction="column"
			/>
			<Override slot="text4" sm-border-color="--color-orange" sm-display="flex" color="inherit">
				- Placement of vacancies on behalf of the employer
				<br />
				<br />
				<br />
				- Internet acquiring
				<br />
				- Subscriptions to vacancies
				<br />
				- Ticket system
				<br />
				<br />
				- Integration with Telegram
			</Override>
			<Override slot="button2" sm-display="none" display="none" />
			<Override slot="button" display="none" />
			<Override
				slot="section"
				sm-padding="0px 0 0px 0"
				sm-margin="0px 0 0 0"
				sm-flex-direction="column"
				sm-height="100%"
				sm-min-height="100%"
				height="100%"
			/>
			<Override slot="box4" sm-display="none" display="none" />
		</Components.CaseProd>
		<Components.CaseProd sm-padding="0px 8px 8px 8px">
			<Override slot="text6" />
			<Override slot="section" />
			<Override slot="image" src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/photo_2022-02-16_16-18-32.jpg?v=2022-02-16T13:52:04.114Z" sm-height="400px" />
			<Override slot="button2" display="none" />
			<Override slot="text">
				Intranet for Rolf
			</Override>
			<Override slot="text2">
				An internal platform for the staff of Europe's largest car dealer.
			</Override>
			<Override slot="text3">
				Functional
			</Override>
			<Override slot="box" background="#32c8d2" sm-margin="0 0px 0px 0px" sm-padding="16px 8px 0px 8px" />
			<Override slot="sectionOverride" />
			<Override slot="SectionContent" />
			<Override slot="text1">
				About the project
			</Override>
			<Override slot="text4">
				Internal company's social net (scrolling news and stories, profiles of colleagues), check salary data, kpi fulfillment. The possibility of ordering certificates, approval of vacation
			</Override>
			<Override slot="text5" />
			<Override slot="box4" sm-display="none" display="none" />
		</Components.CaseProd>
		<Components.CaseProd margin="0 0 0 0" sm-padding="0px 8px 8px 8px">
			<Override slot="text6" sm-display="block">
				Бэкенд: Directual,{" "}
				<br />
				Фронт: React/Quarkly
			</Override>
			<Override
				slot="box"
				sm-margin="0 0px 0px 0px"
				sm-padding="16px 8px 0px 8px"
				color="--darkL1"
				background="#ffd713"
			/>
			<Override slot="SectionContent" sm-height="100%" />
			<Override slot="text4" sm-display="block">
				- Smart selection of links
				<br />
				- CRM system
				<br />
				- Internet acquiring
				<br />
				- Ticket system
				<br />
				- Integration with Telegram
			</Override>
			<Override slot="text5" sm-display="block" />
			<Override slot="text" margin="40px 0px 46px 0px">
				HighRankLinks
			</Override>
			<Override slot="text3" sm-display="block">
				Functional
			</Override>
			<Override slot="text1">
				About the project
			</Override>
			<Override slot="section" sm-height="100%" sm-min-height="100%" />
			<Override slot="button2" display="none" />
			<Override slot="text2">
				SEO links marketplace with smart link selection
			</Override>
			<Override slot="box4" sm-display="none" display="none" />
			<Override slot="image" sm-height="400px" src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/photo_2022-02-16_16-18-33.jpg?v=2022-02-16T13:55:23.993Z" />
		</Components.CaseProd>
		<Section
			top="80px"
			z-index="4"
			padding="0px 32px 0px 32px"
			position="sticky"
			border-radius="20px 20px 0px 0px"
			margin="0 0 30px 0"
			height="max-content"
			min-height="max-content"
			quarkly-title="more"
			width="100wv"
			min-width="100wv"
			display="none"
			sm-padding="0px 8px 0px 8px"
			sm-display="none"
		>
			<Override
				slot="SectionContent"
				border-radius="20px"
				flex-direction="column"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="center"
			/>
			<Section
				margin="0 0 30px 0"
				sm-margin="0 0 20px 0"
				min-width="100%"
				padding="24px 32px 24px 32px"
				background="--color-orange"
				border-radius="20px 20px 20px 20px"
				sm-padding="24px 8px 24px 8px"
				top="90px"
				z-index="4"
				sm-height="calc(100vh - 100px)"
				min-height="calc(100vh - 110px)"
				position="sticky"
				sm-min-height="calc(100vh - 100px)"
				quarkly-title="more"
				width="100%"
				height="calc(100vh - 110px)"
			>
				<Override
					slot="SectionContent"
					width="100%"
					height="100%"
					min-width="100%"
					color="--dark"
					md-justify-content="flex-start"
					flex-direction="row"
					min-height="100%"
					justify-content="center"
					sm-position="static"
					align-items="center"
					sm-flex-direction="column"
				/>
				<Box
					align-items="center"
					sm-flex-direction="column"
					md-flex-direction="row"
					width="100%"
					display="flex"
					justify-content="center"
					min-width="100%"
					height="100%"
				>
					<Text
						margin="40px 0px 12px 0px"
						height="max-content"
						quarkly-title="process"
						sm-text-align="center"
						min-height="max-content"
						sm-width="100%"
						font="--headline1"
						color="--light"
						width="50%"
						min-width="50%"
						sm-font="--headline2"
						md-font="--headline3Mob"
					>
						Все наши работы
					</Text>
					<Box
						display="flex"
						justify-content="center"
						height="50%"
						align-items="center"
						width="50%"
					>
						<Image
							hover-transform="scale(1.1)"
							sm-width="200px"
							sm-height="200px"
							md-width="200px"
							md-height="200px"
							width="300px"
							height="300px"
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-07T15:26:35.011Z"
							hover-transition="all 0.5s ease-in 0s"
						/>
					</Box>
				</Box>
			</Section>
		</Section>
		<Section
			border-radius="20px 20px 0px 00px"
			sm-background="--color-blanc"
			min-width="100wv"
			position="sticky"
			sm-overflow-x="visible"
			quarkly-title="directual"
			height="calc(100vh - 80px)"
			bottom="auto"
			sm-top="80px"
			sm-padding="0px 0px 0px 0px"
			sm-overflow-y="visible"
			margin="0px 0 0px 0"
			sm-position="sticky"
			z-index="4"
			min-height="calc(100vh - 80px)"
			sm-margin="0px 0 0px 0"
			sm-border-radius="20px 20px 0px 0px"
			width="100wv"
			top="80px"
			padding="30px 32px 30px 32px"
			background="--color-directual"
		>
			<Override
				slot="SectionContent"
				min-width="100%"
				justify-content="flex-start"
				flex-direction="row"
				sm-flex-direction="column"
				sm-overflow-y="hidden"
				width="100%"
				height="100%"
				min-height="100%"
			/>
			<Section
				min-width="100%"
				padding="0px 0 0px 0"
				sm-background="--color-directual"
				z-index="4"
				quarkly-title="lastForm"
				sm-border-radius="20px 20px 0px 0px"
				height="100%"
				position="static"
				margin="0 0 0 0"
				width="100%"
				top="160px"
				min-height="100%"
				sm-padding="24px 8px 8px 8px"
				sm-margin="0 0 0px 0"
			>
				<Override
					slot="SectionContent"
					min-height="100%"
					min-width="100%"
					color="--dark"
					sm-position="static"
					align-items="center"
					width="100%"
					height="100%"
					justify-content="center"
					md-justify-content="flex-start"
					flex-direction="row"
					sm-flex-direction="column"
				/>
				<Box
					width="100%"
					height="max-content"
					margin="0px 0px 0px 10px"
					sm-margin="0px 0px 0px 0px"
					sm-align-items="flex-start"
					display="flex"
					md-flex-direction="column"
					quarkly-title="Box"
					flex-direction="column"
					justify-content="center"
					md-align-items="center"
					sm-justify-content="flex-start"
				>
					<Box
						sm-align-items="flex-start"
						height="max-content"
						margin="0px 0px 60px 0px"
						quarkly-title="headlineBox"
						sm-min-height="max-content"
						sm-margin="0px 0px 44px 0px"
						width="100%"
						display="flex"
						align-items="flex-start"
						sm-text-align="left"
						md-flex-direction="column"
						flex-direction="column"
						justify-content="center"
						sm-justify-content="flex-start"
					>
						<Text
							height="max-content"
							min-height="max-content"
							margin="0px 0px 0px 0px"
							md-font="--headline3Mob"
							quarkly-title="headline"
							align-items="center"
							width="100px"
							sm-font="--h2Mob"
							justify-content="flex-start"
							sm-justify-content="flex-start"
							sm-text-align="left"
							sm-margin="60px 0px 0px 0px"
							font="--headline2"
							color="--light"
							min-width="100%"
							display="flex"
							text-align="left"
						>
							Code Word —
							<br />
							Official partner of DIRECTUAL
						</Text>
					</Box>
					<Box
						sm-min-height="max-content"
						md-align-items="center"
						width="100%"
						display="flex"
						margin="0px 0px 0px 0px"
						md-flex-direction="column"
						quarkly-title="contactsBox"
						flex-direction="column"
						sm-width="100%"
						height="max-content"
						justify-content="flex-start"
						md-width="80%"
					>
						<Box
							min-height="max-content"
							flex-direction="column"
							sm-width="100%"
							sm-min-width="0px"
							align-items="flex-start"
							height="max-content"
							min-width="500px"
							display="none"
							quarkly-title="FORM"
							md-width="80%"
							max-width="500px"
							width="50%"
						>
							<Box
								display="flex"
								quarkly-title="name"
								width="100%"
								min-width="100%"
								height="max-content"
								min-height="max-content"
							>
								<Text
									sm-font="normal 400 16px/1.5 --fontFamily-googleMontserrat"
									height="max-content"
									min-height="max-content"
									md-font="--lead"
									sm-width="max-content"
									font="--lead"
									color="--light"
									margin="00px 0px 0px 0px"
									sm-min-width="max-content"
									quarkly-title="nameText"
									width="max-content"
									min-width="max-content"
									md-margin="0px 0px 0px 0px"
								>
									Ваше Имя
								</Text>
								<Input
									placeholder-color="rgba(255, 255, 255, 0.53)"
									border-radius="0px"
									background="--color-orange"
									sm-color="--light"
									border-style="solid"
									height="25px"
									sm-width="calc(100% - 94px)"
									md-width="244px"
									placeholder="Виктор"
									border-color="--color-light"
									padding="0 0 0 4px"
									margin="0 0 0 8px"
									width="279px"
									quarkly-title="nameInput"
									type="text"
									name="name"
									color="--light"
									border-width="0 0 3px 0"
								/>
							</Box>
							<Box
								margin="12px 0px 0px 0px"
								border-radius="0px"
								quarkly-title="tel"
								width="100%"
								min-width="100%"
								height="max-content"
								min-height="max-content"
								display="flex"
							>
								<Text
									font="--lead"
									min-width="max-content"
									md-font="--lead"
									sm-width="max-content"
									sm-min-width="max-content"
									md-margin="0px 0px 0px 0px"
									quarkly-title="telText"
									color="--light"
									margin="00px 0px 0px 0px"
									width="max-content"
									sm-font="normal 400 16px/1.5 --fontFamily-googleMontserrat"
									height="max-content"
									min-height="max-content"
								>
									Ваш телефон
								</Text>
								<Input
									border-style="solid"
									height="25px"
									padding="0 0 0 4px"
									quarkly-title="telInput"
									sm-color="--light"
									sm-width="calc(100% - 119px)"
									color="--light"
									placeholder="+7 (495) 968-65-00"
									placeholder-color="rgba(255, 255, 255, 0.53)"
									width="250px"
									margin="0 0 0 8px"
									border-radius="0px"
									border-width="0 0 3px 0"
									border-color="--color-light"
									type="tel"
									md-width="214px"
									background="--color-orange"
									name="tel"
								/>
							</Box>
							<Box
								height="max-content"
								min-height="max-content"
								display="flex"
								margin="12px 0px 0px 0px"
								border-radius="0px"
								quarkly-title="mail"
								width="100%"
								min-width="100%"
							>
								<Text
									color="--light"
									margin="00px 0px 0px 0px"
									width="max-content"
									min-width="max-content"
									sm-width="max-content"
									sm-min-width="max-content"
									font="--lead"
									sm-font="normal 400 16px/1.5 --fontFamily-googleMontserrat"
									height="max-content"
									min-height="max-content"
									md-font="--lead"
									quarkly-title="mailText"
								>
									Ваш email
								</Text>
								<Input
									border-color="--color-light"
									height="25px"
									margin="0 0 0 8px"
									md-width="246px"
									border-radius="0px"
									color="--light"
									border-style="solid"
									type="email"
									placeholder="hello@code-word.ru"
									placeholder-color="rgba(255, 255, 255, 0.53)"
									padding="0 0 0 4px"
									border-width="0 0 3px 0"
									width="282px"
									sm-width="calc(100% - 93px)"
									quarkly-title="mailInput"
									background="--color-orange"
									name="mail"
									sm-color="--light"
								/>
							</Box>
							<Button
								width="320px"
								hover-background="--color-dark"
								background="--color-dark"
								sm-width="100%"
								margin="24px 0px 0px 0px"
							>
								Получить консультацию
							</Button>
						</Box>
						<Box
							md-margin="30px 0px 0px 0px"
							sm-width="100%"
							max-width="75%"
							display="flex"
							flex-direction="column"
							md-width="100%"
							md-align-items="center"
							sm-margin="0px 0px 0px 0px"
							sm-min-width="100%"
							min-width="75%"
							quarkly-title="contacts"
							height="max-content"
							align-items="flex-start"
							width="75%"
							min-height="max-content"
						>
							<Text
								font="--text"
								sm-width="100%"
								sm-margin="0px 0px 0px 0px"
								color="--light"
								sm-font="normal 300 20px/1.2 --fontFamily-googleMontserrat"
								height="max-content"
								margin="0px 0px 0px 0px"
								min-height="max-content"
								sm-min-width="100%"
								width="100%"
								min-width="100% border-box"
								md-font="--menu"
								md-margin="10px 0px 0px 0px"
								quarkly-title="telmailCDWRD"
							>
								Quick launch of technically complex projects with unlimited workload.
Without compromises, pain, missed deadlines and your nerves
							</Text>
						</Box>
						<Box
							width="max-content"
							display="none"
							min-width="max-content"
							border-style="solid"
							align-items="flex-start"
							md-align-items="center"
							sm-margin="0px 0px 0px 0px"
							border-width="1px"
							min-height="max-content"
							flex-direction="column"
							sm-width="100%"
							quarkly-title="contacts"
							md-width="100%"
							max-width="max-content"
							height="max-content"
							md-margin="30px 0px 0px 0px"
							sm-min-width="100%"
							border-color="#f2ecec"
						>
							<Text
								font="normal 500 30px/1.1 --fontFamily-googleMontserrat"
								width="100%"
								sm-width="100%"
								md-margin="10px 0px 0px 0px"
								sm-font="normal 500 24px/1.1 --fontFamily-googleMontserrat"
								height="max-content"
								min-height="max-content"
								md-font="--menu"
								sm-min-width="100%"
								quarkly-title="telmailCDWRD"
								margin="0px 0px 0px 0px"
								min-width="100% border-box"
								color="--light"
								sm-margin="0px 0px 0px 0px"
							>
								Кодовое Слово - Официальный партнер DIRECTUAL
							</Text>
						</Box>
					</Box>
				</Box>
			</Section>
		</Section>
		<Box
			empty-min-height="0px"
			min-height="0px"
			position="absolute"
			sm-min-width="0px"
			quarkly-title="service"
			top="calc(700vh - 410px)"
			sm-min-height="0px"
			background="--color-secondary"
			id="service"
			empty-border-width="1px"
			min-width="0px"
			z-index="0"
			height="0px"
			empty-min-width="0px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			width="0px"
		/>
		<Section
			quarkly-title="menu3"
			width="100wv"
			min-width="100wv"
			height="40px"
			position="sticky"
			z-index="12"
			top="80px"
			sm-padding="1px 8px 1px 8px"
			border-radius="20px 20px 0px 0px"
			padding="1px 32px 1px 32px"
			background="--color-light"
			sm-border-radius="15px 15px 0px 0px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				align-items="flex-start"
			/>
			<Link href="#service" color="--dark" font="--menu" text-decoration-line="initial">
				Servicies
			</Link>
		</Section>
		<Section
			width="100wv"
			min-width="100wv"
			z-index="4"
			top="120px"
			quarkly-title="Section4"
			height="calc(100vh - 120px)"
			min-height="calc(100vh - 120px)"
			padding="24px 32px 24px 32px"
			position="sticky"
			sm-padding="8px 8px 8px 8px"
			sm-background="--color-light"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				color="--dark"
				justify-content="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
			/>
			<Text
				color="--dark"
				md-font="--h1Mob"
				margin="60px 0px 0px 0px"
				quarkly-title="headline"
				font="normal 600 90px/0.8 --fontFamily-googleMontserrat"
				sm-font="--h1Mob"
			>
				Your business{" "}
				<br />
				<Strong
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
				>
					is unique
				</Strong>
			</Text>
			<Text
				sm-min-width="100%"
				sm-font="--leadMob"
				quarkly-title="description"
				font="--text"
				color="--dark"
				margin="40px 0px 0px 0px"
				width="60%"
				min-width="60%"
				sm-width="100%"
				sm-margin="44px 0px 0px 0px"
				md-width="100%"
			>
				We know that!
				<br />
				Therefore, we select solutions that will ensure{" "}
				<Strong
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					sm-font="normal 500 5vw/1.2 --fontFamily-googleMontserrat"
					sm-color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
				>
					growth of your business.{" "}
				</Strong>
			</Text>
		</Section>
		<Section
			top="120px"
			quarkly-title="serviceAPPS"
			position="sticky"
			z-index="4"
			sm-height="max-content"
			width="100wv"
			min-width="100wv"
			min-height="calc(100vh - 100px)"
			padding="24px 32px 24px 32px"
			background="--color-dark"
			sm-min-height="calc(100vh - 100px)"
			sm-padding="24px 8px 24px 8px"
			border-radius="20px 20px 0px 0px"
			height="calc(100vh - 100px)"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
			/>
			<Text
				color="--light"
				sm-width="100%"
				font="--headline2"
				margin="36px 0px 0px 0px"
				height="max-content"
				quarkly-title="headline"
				width="600px"
				min-width="600px"
				sm-font="--headline3Mob"
				min-height="max-content"
				md-font="--headline2"
				sm-min-width="100%"
				md-margin="100px 0px 0px 0px"
				sm-margin="16px 0px 16px 0px"
			>
				Web Application{" "}
				<br />
				<Span
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
				>
					Development
				</Span>
			</Text>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
			>
				<Text
					min-width="50%"
					sm-width="100%"
					sm-font="--leadMob"
					md-font="--text"
					md-width="100%"
					md-min-width="100%"
					width="75%"
					color="--light"
					margin="30px 0px 0px 0px"
					sm-min-width="100%"
					sm-margin="0px 0px 0px 0px"
					font="--lead"
				>
					We develop CRM systems, market places, warehouse accounting systems, booking systems - in other words, everything that can be launched in a browser on a PC or on a phone. We connect personal accounts, online payment, e-mail, sms notifications, document’s development.
					<br />
					<br />
					We create{"  "}
					<Span
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							color="--orange"
							hyphens="manual"
						>
							cool solutions{" "}
						</Strong>
					</Span>
					that are tailored to your business! Without compromises and crutches!{"  "}
				</Text>
				<Button
					border-color="--color-orange"
					color="--orange"
					font="--base"
					margin="30px 0px 0px 0px"
					sm-margin="20px 0px 0px 0px"
					height="40px"
					border-width="1px"
					border-style="solid"
					background="rgba(0, 119, 204, 0)"
					display="none"
					border-radius="4px"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			min-width="100wv"
			height="calc(100vh - 100px)"
			padding="24px 32px 24px 32px"
			sm-height="max-content"
			quarkly-title="serviceCHATS"
			background="--color-light"
			position="sticky"
			sm-padding="24px 8px 24px 8px"
			border-radius="20px 20px 0px 0px"
			top="120px"
			width="100wv"
			min-height="calc(100vh - 100px)"
			z-index="4"
			sm-min-height="calc(100vh - 100px)"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
			/>
			<Text
				height="max-content"
				font="--headline2"
				margin="36px 0px 0px 0px"
				sm-font="--headline3Mob"
				md-font="--headline2"
				sm-margin="16px 0px 16px 0px"
				width="600px"
				min-width="600px"
				quarkly-title="headline"
				sm-width="100%"
				md-margin="100px 0px 0px 0px"
				sm-min-width="100%"
				color="--dark"
				min-height="max-content"
			>
				Chatbot{" "}
				<br />
				<Span
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
				>
					Development
				</Span>
			</Text>
			<Box
				justify-content="flex-start"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
			>
				<Text
					md-width="100%"
					md-min-width="100%"
					font="--lead"
					margin="40px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-width="100%"
					md-font="--text"
					color="--dark"
					sm-min-width="100%"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
				>
					We create{" "}
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						<Strong
							word-break="normal"
							hyphens="manual"
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							color="--orange"
						>
							useful and irreplaceable chatbots.
						</Strong>
					</Span>
					.{" "}
					<br />
					<br />
					Our bots can:
					<br />
					- sell goods and services,
					<br />
					- remind about overdue payments,
					<br />
					- notify about tasks,
					<br />
					- send track numbers of deliveries,
					<br />
					- create and accept payments.
					<br />
					<br />
					We can connect the bot to the any social network or messenger
					<br />
					Facebook, Telegram, WhatsApp, Vk, Slack
				</Text>
				<Button
					border-width="1px"
					border-style="solid"
					border-color="--color-orange"
					background="rgba(0, 119, 204, 0)"
					font="--base"
					margin="30px 0px 0px 0px"
					color="--orange"
					display="none"
					border-radius="4px"
					height="40px"
				>
					<div display="none" />
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			sm-padding="24px 8px 24px 8px"
			min-height="calc(100vh - 120px)"
			z-index="4"
			sm-height="calc(100vh - 120px)"
			sm-min-height="calc(100vh - 120px)"
			width="100wv"
			background="--color-orange"
			top="120px"
			padding="24px 32px 24px 32px"
			quarkly-title="serviceAUTO"
			min-width="100wv"
			height="calc(100vh - 120px)"
			position="sticky"
			border-radius="20px 20px 0px 0px"
		>
			<Override
				slot="SectionContent"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
				height="100%"
			/>
			<Text
				width="600px"
				min-height="max-content"
				sm-width="100%"
				sm-min-width="100%"
				sm-margin="16px 0px 16px 0px"
				margin="36px 0px 0px 0px"
				min-width="600px"
				sm-font="--headline3Mob"
				md-font="--headline2"
				font="--headline2"
				color="--light"
				height="max-content"
				md-margin="100px 0px 0px 0px"
				quarkly-title="headline"
			>
				<Span
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--dark"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
				>
					Automation{" "}
				</Span>
				{" "}
				<br />
				of business processes
			</Text>
			<Box
				md-flex-direction="column"
				md-align-items="flex-start"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-flex-direction="column"
				sm-align-items="flex-start"
			>
				<Text
					margin="40px 0px 0px 0px"
					sm-min-width="100%"
					md-min-width="100%"
					font="--lead"
					color="#f2f4f6"
					width="75%"
					min-width="75%"
					sm-width="100%"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
					md-font="--text"
					md-width="100%"
				>
					УWe remove routine from the workflow of your employees - everything that people used to do now is done by smart systems.
					<br />
					<br />
					Concentrate the efforts of employees on{" "}
					<Strong
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
					>
						<Span
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							sm-font="normal 700 5vw/1.2 --fontFamily-googleMontserrat"
							color="--dark"
							overflow-wrap="normal"
						>
							really important tasks
						</Span>
					</Strong>
					, and our systems will do the rest:
					<br />
					- Accepting orders,{" "}
					<br />
					- Creation of invoices, reports and KP,{" "}
					<br />
					- Checking the status of payments under the contract,
					<br />
					- Sending emails to counterparties
					<br />
					<br />
					This and much more can be done automatically, without the participation of people.
				</Text>
				<Button
					border-width="1px"
					font="--base"
					margin="30px 0px 0px 0px"
					border-radius="4px"
					height="40px"
					border-style="solid"
					border-color="--color-orange"
					background="rgba(0, 119, 204, 0)"
					color="--orange"
					display="none"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Section
			position="sticky"
			quarkly-title="servicePROMO"
			border-radius="20px 20px 0px 0px"
			display="none"
			width="100wv"
			min-height="calc(100vh - 100px)"
			padding="24px 32px 24px 32px"
			background="--color-light"
			top="120px"
			z-index="4"
			sm-min-height="calc(100vh - 100px)"
			min-width="100wv"
			height="calc(100vh - 100px)"
			sm-height="max-content"
			sm-padding="24px 8px 24px 8px"
		>
			<Override
				slot="SectionContent"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				width="100%"
			/>
			<Text
				font="--headline2"
				color="--dark"
				sm-min-width="100%"
				sm-margin="16px 0px 16px 0px"
				margin="36px 0px 0px 0px"
				sm-font="--headline3Mob"
				md-font="--headline2"
				quarkly-title="headline"
				width="600px"
				min-height="max-content"
				sm-width="100%"
				md-margin="100px 0px 0px 0px"
				min-width="600px"
				height="max-content"
			>
				Продвижение
				<br />
				<Span
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--orange"
					overflow-wrap="normal"
				>
					товаров и услуг
				</Span>
			</Text>
			<Box
				sm-flex-direction="column"
				sm-align-items="flex-start"
				md-flex-direction="column"
				md-align-items="flex-start"
				justify-content="flex-start"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					md-min-width="100%"
					color="--dark"
					margin="40px 0px 0px 0px"
					min-width="75%"
					sm-min-width="100%"
					md-font="--text"
					md-width="100%"
					font="--lead"
					width="75%"
					sm-width="100%"
					sm-font="--leadMob"
					sm-margin="0px 0px 0px 0px"
				>
					Разрабатываем системы для{" "}
					<Strong>
						автоматического создания{" "}
					</Strong>
					рекламных кампаний, креативов.
					<br />
					(контекстная реклама, таргетинговая реклама, рассылки)
					<br />
					Интегрируем все рекламные кабинеты, CRM-систему и складской учет.{" "}
					<br />
					<br />
					Гибкая настройка под реалии вашего бизнеса и ваших клиентов.
				</Text>
				<Button
					border-width="1px"
					border-color="--color-orange"
					background="rgba(0, 119, 204, 0)"
					color="--orange"
					font="--base"
					height="40px"
					border-style="solid"
					display="flex"
					margin="30px 0px 0px 0px"
					border-radius="4px"
				>
					Интересно!
				</Button>
			</Box>
		</Section>
		<Box
			width="0px"
			min-height="0px"
			quarkly-title="about"
			empty-border-width="1px"
			empty-border-color="LightGray"
			background="--color-secondary"
			top="calc(1100vh - 810px)"
			empty-min-width="0px"
			min-width="0px"
			empty-border-style="solid"
			position="absolute"
			height="0px"
			z-index="0"
			id="about"
			empty-min-height="0px"
		/>
		<Box
			empty-min-width="0px"
			empty-border-style="solid"
			height="0px"
			min-width="0px"
			z-index="0"
			empty-border-color="LightGray"
			quarkly-title="contacts"
			position="absolute"
			empty-min-height="0px"
			min-height="0px"
			background="--color-secondary"
			id="contacts"
			empty-border-width="1px"
			width="0px"
			top="calc(1300vh - 1030px)"
		/>
		<Section
			z-index="12"
			quarkly-title="menu4"
			border-radius="20px 20px 0px 0px"
			sm-border-radius="15px 15px 0px 0px"
			width="100wv"
			padding="1px 32px 1px 32px"
			position="sticky"
			sm-padding="1px 8px 1px 8px"
			background="--color-dark"
			sm-margin="20px 0 0 0"
			min-width="100wv"
			height="40px"
			top="120px"
		>
			<Override
				slot="SectionContent"
				align-items="flex-start"
				width="100%"
				height="100%"
				min-height="100%"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
			/>
			<Link href="#about" color="--light" font="--menu" text-decoration-line="initial">
				About{" "}
			</Link>
		</Section>
		<Section
			min-height="calc(100vh - 160px)"
			margin="0px 0px 0px 0px"
			top="160px"
			background="--color-dark"
			width="100wv"
			padding="24px 32px 24px 32px"
			z-index="4"
			height="calc(100vh - 160px)"
			overflow-y="visible"
			min-width="100wv"
			position="sticky"
			sm-padding="24px 8px 44px 8px"
			sm-bottom="avto"
			quarkly-title="aboutPreview"
			sm-top="avto"
			sm-margin="0px 0px 0px 0px"
		>
			<Override
				slot="SectionContent"
				min-width="100%"
				color="--dark"
				justify-content="flex-start"
				overflow-y="visible"
				max-width="100%"
				width="100%"
				height="100%"
				min-height="100%"
			/>
			<Box
				min-height="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				height="100%"
			>
				<Text
					sm-font="--leadMob"
					sm-margin="4px 0px 24px 0px"
					quarkly-title="description"
					font="--lead"
					color="--light"
					sm-width="100%"
					md-font="--lead"
					max-width="930px"
					margin="0px 0px 10px 0px"
					width="100%"
					sm-min-width="100%"
				>
					Here they usually write a pretentious text about a friendly team that shares your values, a team that is constantly developing, loves and cares about you...
				</Text>
				<Text
					md-font="--headline3Mob"
					quarkly-title="description"
					font="--headline2"
					margin="0px 0px 0px 0px"
					width="75%"
					min-width="75%"
					sm-font="--h1Mob"
					color="--light"
					sm-width="100%"
					sm-min-width="100%"
					sm-margin="4px 0px 0px 0px"
				>
					We decided to write{" "}
					<br />
					<Strong
						sm-font="800 14vw/1.0 --fontFamily-googleMontserrat"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						color="--orange"
					>
						{" "}the truth.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section
			margin="0px 0px 0px 0px"
			sm-margin="0px 0px 0px 0px"
			sm-background="rgba(24, 25, 31, 0)"
			sm-padding="8px 8px 24px 8px"
			min-width="100wv"
			padding="24px 32px 24px 32px"
			position="sticky"
			z-index="4"
			max-height="calc(100vh - 160px)"
			width="100wv"
			overflow-y="scroll"
			quarkly-title="about"
			top="160px"
			height="calc(100vh - 160px)"
			min-height="calc(100vh - 160px)"
		>
			<Override
				slot="SectionContent"
				sm-justify-content="flex-start"
				height="100%"
				min-height="400px"
				background="--color-light"
				sm-min-height="386px"
				padding="30px 20px 30px 20px"
				width="100%"
				min-width="100%"
				color="--dark"
				justify-content="center"
				overflow-y="hidden"
				border-radius="20px"
				sm-padding="8px 8px 0px 8px"
				sm-height="100%"
			/>
			<Box quarkly-title="aboutBox" sm-height="max-content" sm-min-height="max-content">
				<Text
					md-font="--headline3Mob"
					quarkly-title="headline"
					md-min-width="100%"
					color="--dark"
					sm-width="100%"
					sm-font="--headline3Mob"
					sm-margin="24px 0px 0px 0px"
					sm-min-width="100%"
					md-width="100%"
					font="--headline3"
					margin="0px 0px 0px 0px"
					width="75%"
					min-width="75%"
				>
					<Strong
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						color="--orange"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
					>
						We love and know
					</Strong>
					{" "}how to create complex products!
				</Text>
				<Text
					min-width="75%"
					sm-width="100%"
					sm-min-width="100%"
					sm-margin="16px 0px 16px 0px"
					md-font="--text"
					quarkly-title="description"
					font="--lead"
					color="--dark"
					margin="20px 0px 20px 0px"
					width="75%"
					sm-font="--leadMob"
				>
					This is what we have been doing for more than 10 years. We work only under a contract, with clear indicators, so we are
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					/>
					<Strong
						overflow-wrap="normal"
						text-indent="0"
						hyphens="manual"
						word-break="normal"
						white-space="normal"
						text-overflow="clip"
						color="--orange"
					>
						{" "}financially interested in your success
					</Strong>
					.
					<Strong
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
					>
						{" "}
					</Strong>
					<br />
					We create not only the "technical part", but also{" "}
					<Span sm-color="--orange">
						<Strong>
							help to create a product{" "}
						</Strong>
					</Span>
					<Strong />
					that will be useful and interesting to your customers
				</Text>
			</Box>
			<Text
				min-width="75%"
				sm-margin="0px 0px 0px 0px"
				md-font="--headline3Mob"
				sm-min-width="100%"
				sm-font="--headline3Mob"
				quarkly-title="headline"
				font="--headline3"
				color="--dark"
				margin="0px 0px 0px 0px"
				width="75%"
				sm-width="100%"
				md-width="100%"
				md-min-width="100%"
			>
				Team
			</Text>
			<Box
				margin="8px 10px 0px 0px"
				display="flex"
				flex-direction="row"
				overflow-x="scroll"
				quarkly-title="listingPerson"
				sm-height="max-content"
				sm-display="flex"
			>
				<Components.Person display="none" />
				<Box
					sm-min-width="80px"
					sm-margin="0px 8px 0px 0px"
					width="120px"
					align-items="center"
					min-width="120зч"
					quarkly-title="person"
					md-width="120px"
					md-min-width="120px"
					sm-height="116px"
					sm-width="80px"
					margin="0px 16px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						border-radius="10px"
						min-height="120px"
						md-min-width="100px"
						sm-height="80px"
						sm-min-width="80px"
						sm-min-height="80px"
						width="120px"
						height="120px"
						md-height="100px"
						md-min-height="100px"
						quarkly-title="photo"
						min-width="120px"
						md-width="100px"
						src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/photo_2021-09-25_16-15-11.jpg?v=2022-01-07T12:24:11.222Z"
						object-fit="cover"
						sm-width="80px"
						margin="0px 0px 0px 0px"
					/>
					<Text
						color="--dark"
						sm-width="100%"
						sm-min-width="100%"
						sm-min-height="max-content"
						font="--base"
						text-align="center"
						width="100%"
						sm-font="--baseMob"
						quarkly-title="name"
						sm-height="max-content"
						margin="0px 0px 0px 0px"
						sm-margin="2px 0px 0px 0px"
						md-font="--baseMob"
						min-width="100%"
					>
						Dmitry
					</Text>
				</Box>
				<Box
					flex-direction="column"
					min-width="120зч"
					quarkly-title="person"
					sm-width="80px"
					md-min-width="120px"
					sm-min-width="80px"
					sm-margin="0px 8px 0px 0px"
					margin="0px 16px 0px 0px"
					display="flex"
					width="120px"
					align-items="center"
					md-width="120px"
					sm-height="116px"
				>
					<Image
						min-width="120px"
						md-width="100px"
						width="120px"
						src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/photo_2021-10-11_21-00-28.jpg?v=2022-01-07T12:36:03.981Z"
						border-radius="10px"
						quarkly-title="photo"
						min-height="120px"
						md-height="100px"
						md-min-width="100px"
						md-min-height="100px"
						height="120px"
						sm-height="80px"
						sm-min-width="80px"
						sm-width="80px"
						sm-min-height="80px"
						object-fit="cover"
						margin="0px 0px 0px 0px"
					/>
					<Text
						sm-width="100%"
						sm-margin="2px 0px 0px 0px"
						text-align="center"
						sm-height="max-content"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="--baseMob"
						quarkly-title="name"
						sm-min-height="max-content"
						color="--dark"
						sm-font="--baseMob"
						sm-min-width="100%"
						min-width="100%"
						font="--base"
					>
						Kate
					</Text>
				</Box>
				<Box
					margin="0px 16px 0px 0px"
					flex-direction="column"
					width="120px"
					align-items="center"
					md-width="120px"
					sm-min-width="80px"
					sm-width="80px"
					display="flex"
					min-width="120зч"
					quarkly-title="person"
					md-min-width="120px"
					sm-margin="0px 8px 0px 0px"
					sm-height="116px"
				>
					<Image
						height="120px"
						src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/photo_2022-02-16_20-09-46.jpg?v=2022-02-16T17:12:32.899Z"
						object-fit="cover"
						border-radius="10px"
						width="120px"
						md-width="100px"
						md-min-width="100px"
						sm-width="80px"
						sm-height="80px"
						quarkly-title="photo"
						min-width="120px"
						min-height="120px"
						sm-min-height="80px"
						md-height="100px"
						md-min-height="100px"
						sm-min-width="80px"
						margin="0px 0px 0px 0px"
					/>
					<Text
						color="--dark"
						sm-min-width="100%"
						sm-margin="2px 0px 0px 0px"
						quarkly-title="name"
						font="--base"
						margin="0px 0px 0px 0px"
						width="100%"
						sm-min-height="max-content"
						min-width="100%"
						sm-width="100%"
						sm-font="--baseMob"
						md-font="--baseMob"
						text-align="center"
						sm-height="max-content"
					>
						Dmitry
					</Text>
				</Box>
				<Box
					align-items="center"
					min-width="120зч"
					quarkly-title="person"
					md-width="120px"
					md-min-width="120px"
					margin="0px 16px 0px 0px"
					flex-direction="column"
					width="120px"
					sm-margin="0px 8px 0px 0px"
					sm-height="116px"
					display="flex"
					sm-min-width="80px"
					sm-width="80px"
				>
					<Image
						min-height="120px"
						md-min-width="100px"
						sm-width="80px"
						sm-height="80px"
						src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/photo_2022-02-15_16-38-41.jpg?v=2022-02-15T13:39:40.358Z"
						object-fit="cover"
						quarkly-title="photo"
						md-height="100px"
						md-min-height="100px"
						sm-min-height="80px"
						sm-min-width="80px"
						width="120px"
						height="120px"
						border-radius="10px"
						min-width="120px"
						md-width="100px"
						margin="0px 0px 0px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						width="100%"
						sm-margin="2px 0px 0px 0px"
						sm-min-width="100%"
						text-align="center"
						sm-height="max-content"
						sm-min-height="max-content"
						font="--base"
						color="--dark"
						sm-width="100%"
						min-width="100%"
						sm-font="--baseMob"
						md-font="--baseMob"
						quarkly-title="name"
					>
						Timur
					</Text>
				</Box>
				<Box
					md-min-width="120px"
					sm-margin="0px 8px 0px 0px"
					sm-height="116px"
					flex-direction="column"
					width="120px"
					min-width="120зч"
					quarkly-title="person"
					sm-min-width="80px"
					sm-width="80px"
					margin="0px 16px 0px 0px"
					display="flex"
					align-items="center"
					md-width="120px"
				>
					<Image
						src="https://uploads.quarkly.io/620b616f021bad00238faf49/images/photo_2022-02-15_18-42-00.jpg?v=2022-02-15T16:27:47.817Z"
						min-height="120px"
						md-height="100px"
						md-min-height="100px"
						sm-width="80px"
						sm-height="80px"
						border-radius="10px"
						md-width="100px"
						md-min-width="100px"
						width="120px"
						height="120px"
						quarkly-title="photo"
						min-width="120px"
						sm-min-width="80px"
						sm-min-height="80px"
						object-fit="cover"
						margin="0px 0px 0px 0px"
					/>
					<Text
						sm-min-width="100%"
						text-align="center"
						color="--dark"
						sm-font="--baseMob"
						quarkly-title="name"
						sm-height="max-content"
						margin="0px 0px 0px 0px"
						width="100%"
						sm-width="100%"
						sm-margin="2px 0px 0px 0px"
						font="--base"
						md-font="--baseMob"
						sm-min-height="max-content"
						min-width="100%"
					>
						Elena
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.LastForm
			z-index="5"
			position="sticky"
			top="160px"
			bottom="auto"
			height="calc(100vh - 200px)"
			min-width="100wv"
			sm-padding="8px 8px 16px 8px"
			padding="24px 32px 24px 32px"
			max-width="100wv"
			margin="0px 0 0px 0"
			sm-min-height="calc(100vh - 160px)"
			sm-height="calc(100vh - 160px)"
		>
			<Override slot="box2" md-width="80%" md-align-items="center" sm-width="100%" />
			<Override slot="box" sm-justify-content="flex-start" md-align-items="center" sm-align-items="flex-start" />
			<Override slot="sectionOverride" max-width="100%" />
			<Override slot="section" height="100%" />
			<Override
				slot="box7"
				md-align-items="center"
				min-width="500px"
				max-width="500px"
				sm-align-items="flex-start"
				md-width="100%"
			/>
			<Override slot="box1" sm-text-align="left" sm-align-items="flex-start" sm-justify-content="flex-start" />
			<Override
				slot="text"
				sm-text-align="left"
				sm-justify-content="flex-start"
				sm-font="--h2Mob"
				sm-margin="10vh 0px 0px 0px"
			>
				Order{" "}
				<br />
				a dream project!
			</Override>
			<Override slot="box3" min-width="500px" max-width="500px" />
			<Override slot="linkBox0" sm-width="max-content" display="none" />
			<Override slot="SectionContent" max-width="100%" width="100%" />
			<Override slot="text1">
				Your Name
			</Override>
			<Override slot="text2">
				Your phone
			</Override>
			<Override slot="text3">
				Your email
			</Override>
			<Override slot="input2" width="285px" placeholder="hello@code-word.agency" required />
			<Override slot="input1" width="276px" sm-width="296px" />
			<Override slot="text5">
				hello@code-word.agency
			</Override>
			<Override slot="input" placeholder="Ann" required />
			<Override slot="button">
				Consult for free
			</Override>
			<Override slot="linkBox1" href="mailto:hello@code-word.agency" />
		</Components.LastForm>
		<Components.DirectualApiProvider
			inner-width="100vw"
			margin="0px 0 0 0"
			padding="0 0 0 0"
			min-height="max-content"
			width="100vw"
			display="none"
			cells-number-total="1"
			height="max-content"
			appID="cf533faa-d52a-4193-8e5d-358b15570726"
			max-height="max-content"
			max-width="100vw"
			min-width="100vw"
			inner-max-width="100wv"
		>
			<Override
				slot="Content"
				min-height="max-content"
				max-height="max-content"
				width="100%"
				min-width="100%"
				max-width="100%"
				margin="0 0 0 0"
				padding="0 0 0 0"
				height="max-content"
			>
				<Override
					slot="Cell 0th"
					height="max-content"
					min-height="max-content"
					max-height="max-content"
					width="100%"
					min-width="100%"
				/>
				<Override slot="cell-0" />
			</Override>
		</Components.DirectualApiProvider>
		<Components.PrivatContent />
		<Components.DocLink sm-display="none" display="none" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});